import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import DefaultLayout from "../components/layouts/DefaultLayout";
import SEO from "../components/layouts/SEO";
import Button from "../components/UI/Button";
import useBoolean from "../hooks/useBoolean";
import Hero from "../components/StaticPage/Hero";

import person1 from "../images/about/team/person-1.png";
import person2 from "../images/about/team/person-2.png";
import person3 from "../images/about/team/person-3.png";
import person4 from "../images/about/team/person-4.png";

const About: React.FC = () => {
  const [isHoverBtn, setHoverBtn] = useBoolean();

  return (
    <DefaultLayout>
      <SEO
        title="Terms & Conditions | Ensemble"
        url="https://ensembleai.io/about"
        ogImage="https://ensembleai.io/assets/img/home.png"
      />
       <Hero
        title="Supply Of Service Terms & Conditions"
        backgroundColor={["#40b7de", "#1976e0"]}
        description="Framework Services Agreement"
      />
     
      <div className="max-w-[1188px] p-6 lg:pb-20 lg:pt-16 mx-auto">

  <div className="flex lg:gap-16 lg:flex-row flex-col gap-6">
    <div className="w-full space-y-6 text-black">
      



<h1>FRAMEWORK SERVICES AGREEMENT</h1>

<h1>BACKGROUND</h1>
<p>(A) Ensemble and Customer have agreed that Ensemble will provide certain services to Customer.</p>
<p>(B) The Services (as defined below) ordered by Customer are more particularly detailed on one or more separately stated Order Forms.</p>
<p>(C) These Ensemble Supply of Service Terms and Conditions together with the terms and conditions of any applicable Order Form(s) (as defined below) states the terms and conditions under which Ensemble will supply and Customer may use the Services.</p>

<h1>AGREED TERMS</h1>
<h2>1. DEFINITIONS AND INTERPRETATIONS</h2>
<p>1.1 The definitions and rules of interpretation in this clause apply to these Ensemble Supply of Service Terms and Conditions (the “Ensemble Terms”) and to all Order Forms.</p>
<ul>
<li>"Business Hours" means UK standard business hours of 9am - 6pm GMT or BST as applicable Monday to Friday excluding UK public holidays.</li>
<li>“Ensemble” means Ensemble AI Limited incorporated and registered in England and Wales with company number 15610607 whose registered office is at 128 City Road, London, EC1V 2NX, United Kingdom</li>
<li>"Ensemble's Equipment" means any equipment, including tools, systems, software, cabling or facilities, provided by Ensemble or its subcontractors and used directly or indirectly in the supply of the Services.</li>
<li>"Confidential Information" means information disclosed by (or on behalf of) one party to the other party in connection with or in anticipation of this Agreement or any Order Form (including the content of this Agreement and all Order Forms) that is marked as confidential or, from its nature, content or the circumstances in which it is disclosed, might reasonably be supposed to be confidential. It does not include information that the recipient already knew, that becomes public through no fault of the recipient, that was independently developed by the recipient or that was lawfully given to the recipient by a third party.</li>
<li>"Customer's Manager" means the Customer's manager for the Services, appointed in accordance with clause 5.1.</li>
<li>"Deliverables" means all Documents, products and materials developed by Ensemble or its agents, subcontractors, consultants and employees in relation to the Services in any form, including computer programs, data, reports and specifications (including drafts).</li>
<li>"Document" includes, in addition to any document in writing, any drawing, map, plan, diagram, design, picture or other image, tape, disk or other device or record embodying information in any form.</li>
<li>"Effective Date" means the date written in the applicable Order Form(s).</li>
<li>"Group Company" means in relation to each of the parties:</li>
<ul>
<li>(a) any parent company of that party; and</li>
<li>(b) any corporate body of which that party directly or indirectly has control or which is directly or indirectly controlled by the same person or group of persons as that party.</li>
</ul>
<li>"Intellectual Property Rights" means all patents, rights to inventions, utility models, copyright and related rights, trade marks, service marks, trade, business and domain names, rights in trade dress or get-up, rights in goodwill or to sue for passing off, unfair competition rights, rights in designs, rights in computer software, database rights, topography rights, moral rights, rights in Confidential Information (including Know-How and trade secrets) and any other intellectual property rights, in each case whether registered or unregistered and including all applications for and renewals or extensions of such rights, and all similar or equivalent rights or forms of protection in any part of the world.</li>
<li>"In-put Material" means all Documents, information and materials provided by the Customer relating to the Services, including computer software, data, reports and specifications.</li>
<li>"Know-How" means unpatented technical information (including information relating to inventions, discoveries, concepts, methodologies, models, research, development, and testing procedures; the results of experiments, tests, and trials; manufacturing processes, techniques, and specifications; quality control data, analyses, reports, and submissions) that is not in the public domain.</li>
<li>"Order Form" means each fully executed Ensemble order form that incorporates the Ensemble Terms and describes the Services to be provided by Ensemble from time to time as agreed in accordance with clause 3 of this Agreement.</li>
<li>"Pre-existing Materials" means all Documents, information and any tangible or intangible materials (including hardware, software, source code, methodologies, know how, processes, techniques, ideas, concepts, technologies and data) provided by Ensemble relating to the Services which existed prior to the commencement of the applicable Order Form or are independently developed, including computer software, data, reports and specifications, and any modifications and enhancements thereto.</li>
<li>"Services" means the services to be provided by Ensemble from time to time under these Ensemble Terms and t in accordance with the applicable Order Form.</li>
<li>"VAT" means value added tax chargeable under English law for the time being and any similar additional tax.</li>
</ul>
<p>1.2 Where the words include(s), including or in particular are used in these Ensemble Terms or any Order Form, they are deemed to have the words without limitation following them.</p>
<p>1.3 References to clauses are to the clauses of these Ensemble Terms.</p>
<p>1.4 A reference to a party includes its successors and permitted assigns.</p>

<h2>2. COMMENCEMENT AND DURATION</h2>
<p>2.1 Ensemble shall provide the Services to the Customer on the terms and conditions of these Ensemble Terms and the applicable Order Form.</p>
<p>2.2 Ensemble shall:</p>
<ul>
<li>(a) provide the Services to the Customer from the date specified in the applicable Order Form; and</li>
<li>(b) subject to clause 11, continue to provide the Services until delivered in accordance with the applicable Order Form.</li>
</ul>

<h2>3. ORDER FORM(S)</h2>
<p>3.1 Each Order Form shall form a separate agreement (“Agreement”) between the Customer and Ensemble on the terms contained in the relevant Order Form and the Ensemble Terms. If any Order Form (as may be amended by pursuant to clause 6 below) conflicts with the provisions of the Ensemble Terms then (unless expressly stated otherwise in an Order Form or the document amending the Order Form) the Order Form shall prevail. Each party agrees that the terms printed on or incorporated by reference in an accepted purchase order shall not be binding on either party.</p>
<p>3.2 Once the Order Form has been signed by both parties, no amendment shall be made to it except in accordance with clause 6 below.</p>

<h2>4. ENSEMBLE ANALYTICS'S OBLIGATIONS</h2>
<p>4.1 Ensemble shall provide the Services and deliver the Deliverables to the Customer in accordance with the applicable Order Form with reasonable skill and care and in accordance with generally recognised commercial practices and standards.</p>
<p>4.2 Ensemble shall use reasonable endeavours to meet project milestones (if any) specified in an Order Form.</p>
<p>4.3 Ensemble shall deliver all Services from Ensemble's offices unless otherwise mutually agreed between the parties. If Ensemble is required to travel to Customer's premises or any other third party premises in order to deliver the Services to Customer, Customer shall be responsible for Ensemble's reasonable costs as mutually agreed between the parties.</p>
<p>4.4 Statements made by Ensemble relating to the Services, and all surveys, forecasts, recommendations and opinions (together "Projections") in any Deliverables are made in good faith on the basis of information available at the time. Projections are not a representation, undertaking or warranty as to outcome or achievable results.</p>
<p>4.5 Implementation of the results of the Services and completion of any project of which the Services form part may require the involvement or supervision of or giving advice to third parties engaged by the Customer. The Customer is responsible for obtaining independent advice before entering into any legally binding commitment with any such third parties.</p>
<p>4.6 Ensemble may, during its performance of the Services, make statements about or recommendations of third party software, equipment or services. No warranty shall be attributable to Ensemble with respect to such software, equipment or services, and the Customer shall look solely to the warranties and remedies provided by any such third party with whom it may contract.</p>
<p>4.7 No conditions, warranties or other terms apply to any Services (including any software) supplied by Ensemble under any Order Form unless expressly set out in the Ensemble Terms or an applicable Order Form. Subject to clause 10.2 (b), no implied conditions, warranties or other terms apply (including any implied terms as to satisfactory quality, fitness for purpose or conformance with description). In addition, Ensemble does not warrant or enter into any other term to the effect that any software or any technology provided in connection with any Order Form will be entirely free from defects or that its operation will be entirely error free.</p>

<h2>5. CUSTOMER'S OBLIGATIONS</h2>
<p>5.1 The Customer shall:</p>
<ul>
<li>(a) cooperate with Ensemble in all matters relating to the Services and carry out all other responsibilities set out in the Ensemble Terms and all Order Forms in a timely and efficient manner. In the event of any delays in Customer's provision of such assistance as agreed by the parties, Ensemble may adjust any agreed timetable or delivery schedule as reasonably necessary;</li>
<li>(b) appoint the Customer's Manager, who shall have the authority contractually to bind the Customer on matters relating to the Services;</li>
<li>(c) provide, for Ensemble, its agents, subcontractors, consultants and employees, in a timely manner and at no charge, access to the Customer's premises, staff, office accommodation, Customer's Equipment, data and other facilities (including remote access to all systems using the Customer's preferred remote access solution) as reasonably required by Ensemble for the purposes of providing the Services;</li>
<li>(d) provide, such In-put Material and other information as Ensemble may reasonably require and ensure that it is accurate in all material respects;</li>
<li>(e) provide prior notice to Ensemble of any changes, works, projects undertaken by the Customer or any third party which are likely to affect the Services or the manner in which the Services are provided to Customer by Ensemble pursuant to the applicable Order Forms and continuously collaborate with Ensemble with all due skill and care to minimise or remove any adverse impact which such changes, works and projects may have on the Customer and the benefits which the Customer is deriving from the Services;</li>
<li>(f) If the delivery of the Services involves one or more third parties engaged by Customer, Customer shall facilitate the communication and promote the cooperation between Ensemble and such relevant third parties. If requested by Ensemble, Customer shall cause such third parties to enter into with Ensemble non disclosure agreements reasonably acceptable to Ensemble. Customer further acknowledges and agrees that Ensemble shall not be responsible to Customer if it is unable to comply with its obligations under the Ensemble Terms and/or the applicable Order Form as a result of the acts or omissions of these third parties engaged by Customer with which Ensemble has no contractual relationship.</li>
</ul>
<p>5.2 Subject to prior written approval, Customer shall allow Ensemble to add Customer (including Customer's logo) as a reference on Ensemble's website located at www.Ensemble.io (or such other URL Ensemble may operate from time to time) and in such other media as the parties may agree. Customer agrees to act as a reference for prospective customers of Ensemble and provide input and data sufficient for the production of one publicly accessible case study (if requested by Ensemble).</p>
<p>5.3 Customer shall comply with all reasonable instructions notified by Ensemble to Customer from time to time relating to the Services.</p>

<h2>6. CHANGE CONTROL</h2>
<p>6.1 If either party requests a change to the scope or execution of the Services in an Order Form Ensemble shall, within a reasonable time, provide a written estimate to the Customer of:</p>
<ul>
<li>(a) the likely time required to implement the change;</li>
<li>(b) any necessary variations to Ensemble's charges arising from the change;</li>
<li>(c) the likely effect of the change on the applicable Order Form; and</li>
<li>(d) any other impact of the change on the Ensemble Terms.</li>
</ul>
<p>6.2 If the Customer wishes Ensemble to proceed with the change, Ensemble has no obligation to do so unless and until the parties have agreed in writing the necessary variations to its charges, the Services, the relevant Order Form and any other relevant terms of the Ensemble Terms to take account of the change.</p>
<p>6.3 Notwithstanding clause 6.2, Ensemble may, from time to time on reasonable notice, change the Services in any applicable Order Form in order to comply with any applicable safety or statutory requirements, provided that such changes do not materially affect the nature, scope of, or the charges for the Services or materially adversely affect the Customer.</p>

<h2>7. CHARGES AND PAYMENT</h2>
<p>7.1 In consideration of the provision of the Services, the Customer shall pay the charges set out in the applicable Order Form in the manner set out in the Ensemble Terms and/or the applicable Order Form.</p>
<p>7.2 Where Services are provided on a time and materials basis:</p>
<ul>
<li>(a) the charges payable for the Services shall be calculated in accordance with Ensemble's agreed daily rates as set out in an Order Form;</li>
<li>(b) Unless stated otherwise, Ensemble's agreed daily rates for each individual person are calculated on the basis of an eight hour day, worked during Business Hours;</li>
<li>(c) Ensemble shall be entitled to charge an overtime rate of 50% of the agreed daily rate on a pro-rata basis for each part day or for any time worked by individuals whom it engages on the Services outside Business Hours.</li>
</ul>
<p>7.3 All charges quoted to the Customer shall be exclusive of VAT, which Ensemble shall add to its invoices at the appropriate rate.</p>
<p>7.4 The Customer shall pay each invoice submitted to it by Ensemble in full within 30 days of receipt.</p>
<p>7.5 All payments due to Ensemble shall be in pounds sterling or such other currency as set out in each Order Form.</p>
<p>7.6 Without prejudice to any other right or remedy that it may have, if the Customer fails to pay Ensemble on the due date, Ensemble may charge interest at the rate of 2% per annum above the base rate of Barclays Bank PLC from time to time, from the due date until the date of actual payment, whether before or after judgement, on any charges for the Services which is overdue.</p>
<p>7.7 Neither party may set off any payment obligations it may incur under any Agreement against any fees owed and not yet paid by the other party under that Agreement and/or any other Order Form or agreements between the parties.</p>

<h2>8. INTELLECTUAL PROPERTY RIGHTS</h2>
<p>8.1 Subject to clause 8.2, as between the Customer and Ensemble, all Intellectual Property Rights and all other rights in the Deliverables and the Pre-existing Materials shall be owned by Ensemble. Subject to clause 8.2 and to the Customer paying Ensemble all charges due under the applicable Order Form, Ensemble licenses all such rights to the Customer on a perpetual, non-exclusive, non transferable, royalty free worldwide basis to such extent as is necessary to enable the Customer to make reasonable use of the Deliverables and the Services. Customer will leave in place (and not alter or obscure) all proprietary notices and licenses contained in the Deliverables and the Pre-existing Materials.</p>
<p>8.2 The Customer acknowledges and agrees that, where Ensemble does not own any of the Pre-existing Materials, the Customer's use of rights in Pre-existing Materials is conditional on Ensemble obtaining a written licence (or sub-license) from the relevant licensor or licensors on such terms as will entitle Ensemble to license such rights to the Customer or the Customer obtaining a written licence from the relevant licensor or licensors as the case may be. By way of example only, in delivering the Services, Ensemble may be required to install and/or configure third party applications on Customer's Equipment. The Customer authorises Ensemble to accept such third party applications' terms and conditions on behalf of Customer. Ensemble's Deliverables and Services may include certain open source security management tools, utilities and other software. Such open source software is free software that may be distributed or modified under the terms of their relevant licence. Ensemble disclaims all copyright interest in such open source components. Any fees paid by Customer to Ensemble are not for any open source software. Any licence associated with an open source component applies only to that open source component and not to Ensemble's proprietary software or any other third party licensed software.</p>

<h2>9. CONFIDENTIALITY</h2>
<p>The recipient of any Confidential Information will not disclose that Confidential Information, except to Group Companies, employees and/or professional advisors who need to know it and who have agreed in writing (or in the case of professional advisors are otherwise bound) to keep such information confidential. The recipient will ensure that those people and entities: (a) use such Confidential Information only to exercise rights and fulfil obligations under the Ensemble Terms and the applicable Order Form(s), and (b) keep such Confidential Information confidential using the same degree of care as it uses to protect the confidentiality of its own confidential information of like nature, but no less than a reasonable degree of care. The recipient may also disclose Confidential Information when required by law after giving reasonable notice to the discloser, such notice to be sufficient to give the discloser the opportunity to seek confidential treatment, a protective order or similar remedies or relief prior to disclosure.</p>

<h2>10. LIMITATION OF LIABILITY</h2>
<p>10.1 This clause 10 sets out the entire financial liability of each party (including any liability for the acts or omissions of its employees, agents, consultants and subcontractors) to the other in respect of:</p>
<ul>
<li>(a) any breach of the Ensemble Terms and all Order Forms;</li>
<li>(b) any use made by the Customer of the Services, the Deliverables or any part of them; and</li>
<li>(c) any representation, statement or tortious act or omission (including negligence) arising under or in connection with the Ensemble Terms and all Order Forms.</li>
</ul>
<p>10.2 Nothing in the Ensemble Terms or in any Order Forms limits or excludes either party's liability:</p>
<ul>
<li>(a) for death or personal injury resulting from negligence; or</li>
<li>(b) for fraud or fraudulent misrepresentation; or</li>
<li>(c) for gross negligence or wilful misconduct;</li>
<li>(d) payment of sums properly due and owing to the other in the course of normal performance of the Ensemble Terms and all Order Forms.</li>
</ul>
<p>10.3 Subject to clause 10.1 and clause 10.2, neither party shall be liable under the Ensemble Terms or any Order Form (whether in contract, tort or otherwise) for any:</p>
<ul>
<li>(a) loss of profit;</li>
<li>(b) loss of anticipated savings;</li>
<li>(c) loss of business opportunity;</li>
<li>(d) loss of or corruption of data;</li>
<li>(e) loss or damage resulting from third party claims;</li>
<li>(f) loss of reputation or goodwill; or</li>
<li>(g) indirect or consequential losses;</li>
</ul>
<p>suffered or incurred by the other party,(whether or not such losses were within the contemplation of the parties at the date of the applicable Order Form).</p>
<p>10.4 Either party's total aggregate liability in contract, tort (including negligence or breach of statutory duty), misrepresentation, restitution or otherwise arising in connection with the performance or contemplated performance of the Ensemble Terms or any applicable Order Form shall be limited to 125% of the total amount paid and payable by Customer under the applicable Order Form.</p>
<p>10.5 The Parties understand and agree that the limitations of liability set forth in this clause 10 are a reasonable allocation of risk between the Parties, and, absent such allocation, Ensemble would not be able to charge the prices it is charging for the Services.</p>

<h2>11. TERMINATION</h2>
<p>11.1 Subject to clause 11.2:</p>
<ul>
<li>(a) Each Order Formshall commence on the Effective Date, and remain in full force and effect until all Order Forms have expired or terminated in accordance with their terms and it is terminated by either party giving no less than thirty days prior written notice to the other party;</li>
<li>(b) Each Order Form shall commence on the Order Form Effective Date and shall remain in full force and effect until all Services are delivered as determined by Ensemble.</li>
</ul>
<p>11.2 Subject to clause 11.3, the termination or expiration of an Order Form shall not terminate any other Order Form (including the Ensemble Terms)  unless the parties expressly agree otherwise in writing.</p>
<p>11.3 Without prejudice to any other rights or remedies which the parties may have, either party may suspend or terminate an Order Form without liability to the other immediately on giving notice to the other if:</p>
<ul>
<li>(a) the other party fails to pay any amount due under the applicable Order Form on the due date for payment and remains in default not less than 30 days after being notified in writing to make such payment; or</li>
<li>(b) (i) the other party is in material breach of the Ensemble Terms and/or an Order Form where the breach is incapable of remedy; or (ii) the other party is in material breach of the Ensemble Terms and/or an Order Form where the breach is capable of remedy and fails to remedy that breach within fourteen (14) days after receiving written notice of such breach; or</li>
<li>(c) the other party enters into an arrangement or composition with or for the benefit of its creditors, goes into administration, receivership or administrative receivership, is declared bankrupt or insolvent or is dissolved or otherwise ceases to carry on business; or</li>
<li>(d) any analogous event happens to the other party in any jurisdiction in which it is incorporated or resident or in which it carries on business or has assets.</li>
</ul>
<p>11.4 On termination of an Order Form by the Customer, Customer shall in accordance with the terms set out in clause 7 pay Ensemble all of Ensemble's outstanding unpaid invoices and interest and, in respect of Services supplied but for which no invoice has been submitted, Ensemble may submit an invoice, which shall be payable within 30 days of receipt.</p>
<p>11.5 On termination of an Order Form  by either party:</p>
<ul>
<li>(a) the Customer shall, within a reasonable time, return all of Ensemble's Equipment and Pre-existing Materials; and.</li>
<li>(b) the accrued rights and liabilities of the parties as at termination and the continuation of any provision expressly stated to survive or implicitly surviving termination, shall not be affected.</li>
</ul>

<h2>12. GENERAL</h2>
<p>12.1 Neither party will be liable for any delay or non-performance of its obligations under  the Ensemble Terms or any Order Form to the extent that such delay or non-performance is a result of any condition beyond its reasonable control, including but not limited to, governmental action, acts of terrorism, earthquake, fire, flood or other acts of God, labour conditions, power failures, and Internet disturbances.</p>
<p>12.2 No variation of any Order Form shall be valid unless it is in writing and signed by or on behalf of each of the parties.</p>
<p>12.3 Failure or delay in exercising any right or remedy under the Ensemble Terms any Order Form shall not constitute a waiver of such (or any other) right or remedy.</p>
<p>12.4 If any provision of the Ensemble Terms or Order Form (or part of any provision) is found by any court or other authority of competent jurisdiction to be invalid, illegal or unenforceable, that provision or part provision shall, to the extent required, be deemed not to form part of the Ensemble Terms t or Order Form as applicable and (a) the parties shall immediately commence good faith negotiations to remedy such invalidity; and (b) the validity and enforceability of the other provisions of the Ensemble Terms or Order Form as applicable shall not be affected.</p>
<p>12.5 The Ensemble Termsand the applicable Order Form constitutes the whole agreement between the parties and supersedes any previous arrangement, understanding or agreement between them relating to the subject matter of the Ensemble Terms and the applicable Order Form. Each party acknowledges that in entering into any Order Form  it has not relied upon any oral or written statements, collateral or other warranties, assurances, representations or undertakings which were made by or on behalf of the other party in relation to the subject matter of the Ensemble Terms or an Order Form at any time before its signature (together "Pre-Contractual Statements''), other than those which are set out in the Ensemble Terms or any Order Form. Each party waives all rights and remedies which might otherwise be available to it in relation to such Pre-Contractual Statements. Furthermore, Customer understands its business needs and has determined independently that the Deliverables and Services will meet its needs.</p>
<p>12.6 Except as expressly stated otherwise, nothing in the Ensemble Terms or any Order Form shall create or confer any rights or other benefits in favour of any person other than the parties to the Ensemble Terms and Order Form.</p>
<p>12.7 Except as expressly stated otherwise, nothing in the Ensemble Terms or any Order Form shall create an agency, partnership or joint venture of any kind between the parties.</p>
<p>12.8 Neither party may assign any of its rights or obligations under the Ensemble Terms any Order Form without the prior written consent of the other such consent not to be unreasonably withheld save that either party can assign to any of its Group Companies without the consent of the other.</p>
<p>12.9 All notices must be in English, in writing, addressed to the other party's primary contact and sent to their then current postal address or email address or other address as either party has notified the other in accordance with this clause. All notices shall be deemed to have been given on receipt as verified by written or automated receipt or electronic log (as applicable).</p>
<p>12.10 If any dispute arises between the parties under the Ensemble Terms or any Order Form then the parties will use their best endeavours to ensure that an appropriate dispute resolution process is employed with a view to resolving the same quickly and amicably. If the parties are unable to resolve a dispute arising out of the Ensemble Terms or any Order Form, then, after 30 days either party may refer the dispute to a higher level of management within each respective company for resolution. If after a further 30 days the parties remain unable to resolve such dispute then either party may invoke clause 12.13. The parties agree to employ this dispute resolution process whenever possible but acknowledge that each of them has the right to employ such other dispute resolution and/or enforcement measures as they see fit in order to safeguard their legal rights.</p>
<p>12.11 Neither party shall, without the prior written consent of the other party, at any time from the date of the first Order Form to the expiry of 12 months after the termination of the latest applicable Order Form, solicit or entice away from either party or employ or attempt to employ any person who is, or has been, engaged as an employee, consultant or subcontractor of either party in the provision of the Services. A party shall not be in breach of this clause 12.11 as a result of running a national advertising campaign open to all comers and not specifically targeted at any of the staff or the customers of the other party. If either Ensemble or the Customer commits any breach of this clause 12.11, the breaching party shall, on demand, pay to the claiming party a sum equal to one year's basic salary or the annual fee that was payable by the claiming party to that employee, worker or independent contractor plus the recruitment costs incurred by the claiming party in replacing such person.</p>
<p>12.12 The parties shall: (i) comply with all applicable laws, statutes and regulations relating to anti bribery and anti corruption including to the Bribery Act 2010 (Relevant Requirements); (ii) not engage in any activity, practice or conduct which would constitute an offence under sections 1, 2 or 6 of the Bribery Act 2010 if such activity, practice or conduct had been carried out in the UK; (iii) promptly report to the other party any request or demand for any undue financial or other advantage of any kind received by it in connection with the performance of any applicable Order Form.</p>
<p>12.13 The Ensemble Terms And all Order Forms and all disputes and claims arising out of or in connection with them are governed by English law. With the sole exception of any application for injunctive relief, the parties irrevocably submit to the exclusive jurisdiction of the English courts.</p>



    </div>
  </div>
  </div>
  
     
    </DefaultLayout>
  );
};
 
export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
  }
`;

export default About;
